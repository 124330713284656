import * as React from 'react';
import './index.css';
import Datafeed from '../../Datafeeds/datafeed.js'
import { func } from 'prop-types';
import { Breadcrumbs } from '@material-ui/core';
// import { widget } from '../../charting_library/charting_library.min';
import SaveLoad from '../../Datafeeds/saveload.js'

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent {
	static defaultProps = {
		symbol: 'COMEX:GC',
		interval: '15',
		containerId: 'tv_chart_container',
		libraryPath: '/charting_library/',
		fullscreen: false,
		autosize: true
	};

	tvWidget = null;
	supported_chart_types = {
		'bar': 0,
		'candle': 1,
		'line': 2,
		'area': 3,
		'heikin-ashi': 8,
		'hollow candle': 9,
		'baseline': 10,
		'hi-lo': 12
	}

	componentDidMount() {
		var query_params = new URLSearchParams(this.props.location.search)
		const widgetOptions = {
			symbol: query_params.get('stock') || this.props.symbol,
			// BEWARE: no trailing slash is expected in feed URL
			datafeed: Datafeed,
			interval: this.props.interval,
			container_id: this.props.containerId,
			library_path: this.props.libraryPath,
			save_load_adapter: SaveLoad,
			timezone: 'exchange',
			// symbol_search_request_delay: 500,
			// debug: true,

			locale: getLanguageFromURL() || 'en',
			fullscreen: this.props.fullscreen,
			autosize: this.props.autosize,
            custom_indicators_getter: function(PineJS) {
				return Promise.resolve([
					{
						name: "CupNHandle",
						metainfo: {
							"_metainfoVersion": 40,
							"id": "CupNHandle@tv-basicstudies-1",
							"scriptIdPart": "",
							"name": "CupNHandle",
							"description": "CupNHandle",
							"shortDescription": "CupNHandle",
					
							"is_hidden_study": true,
							"is_price_study": true,
							"isCustomIndicator": true,
					
							"plots": [{"id": "plot_0", "type": "line"}, 
										{"id": "plot_1", "type": "line"}],
							"filledAreas": [
								{
									id: 'filledAreaId1',
									objAId: 'plot_0',
									objBId: 'plot_1',
									title: 'Cup and Handle Area',
									type: 'plot_plot',
								}],
							"defaults": {
								"filledAreasStyle": {
									"filledAreaId1": {
										"color": 'grey',
										"transparency": 80,
										"visible": true,
									},
								},
								"styles": {
									plot_0:
									{
										linestyle: 0,
										visible: true,
										linewidth: 1,
										plottype: 2,
										trackPrice: true,
										transparency: 40,
										color: "green"
									},
									plot_1:
									{
										linestyle: 1,
										visible: true,
										linewidth: 2,
										plottype: 2,
										trackPrice: true,
										transparency: 40,
										color: "red"
									},
								},
					
								// Precision is set to one digit, e.g. 777.7
								"precision": 1,
					
								"inputs": {
									in_0: 15,
									in_1: 200,
									in_2: 0.15,
									in_3: false,
									in_4: 60,
									in_5: 20,
									in_6: 50,
									in_7: 30,
									in_8: 60,
									in_9: true,
									in_10: true
								}
							},
							"styles": {
								"plot_0": {
									// Output name will be displayed in the Style window
									"title": "CupNHandle High",
									"histogramBase": 0,
								},
								"plot_1": {
									// Output name will be displayed in the Style window
									"title": "CupNHandle Low",
									"histogramBase": 0,
								}
							},
							"inputs": [{
									id: "in_0",
									name: "Min Cup Size",
									defval: 15,
									type: "integer",
									min: 1,
									max: 1e4
								},
								{
									id: "in_1",
									name: "Max Cup Size",
									defval: 200,
									type: "integer",
									min: 1,
									max: 1e4
								},
								{
									id: "in_2",
									name: "Cup Edge Delta Percent(WRT Cup Height)",
									defval: 0.15,
									type: "float",
									min: 0.001,
									max: 1.0
								},
								{
									id: "in_3",
									name: "Show Debug Drawing",
									defval: false,
									type: "bool",
								},
								{
									id: "in_4",
									name: "No. of candles in uptrend before CnH",
									defval: 60,
									type: "integer",
									min: 0,
									max: 2000
								},
								{
									id: "in_5",
									name: "Min Handle Retracement (% of cup height)",
									defval: 20,
									type: "integer",
									min: 0,
									max: 200
								},
								{
									id: "in_6",
									name: "Max Handle Retracement (% of cup height)",
									defval: 50,
									type: "integer",
									min: 0,
									max: 200
								},{
									id: "in_7",
									name: "Min Handle Width (% of cup width)",
									defval: 30,
									type: "integer",
									min: 0,
									max: 200
								},
								{
									id: "in_8",
									name: "Max Handle Width (% of cup width)",
									defval: 60,
									type: "integer",
									min: 0,
									max: 200
								},
								{
									id: "in_9",
									name: "Show Only Valid Handles",
									defval: true,
									type: "bool",
								},
								{
									id: "in_10",
									name: "Check Cup 2 Pattern",
									defval: true,
									type: "bool",
								}],
						},
					
						constructor: function() {
								this.init = function(context, inputCallback) {
								this._context = context;
								this._input = inputCallback;
					
								var symbol = PineJS.Std.ticker(this._context);
								symbol = symbol.replace("}", "")
								symbol = symbol.replaceAll("\"", "")

								this._context.new_sym(symbol, PineJS.Std.period(this._context), PineJS.Std.period(this._context));
							};
					
							this.IsUpTrend = function(fromIdx, openSeries, highSeries, lowSeries, closeSeries, candleCount) {
								var isUpTrend = false;
								
								var highest = highSeries.get(fromIdx);
								for( var i = fromIdx; i <= fromIdx + candleCount; i++)
								{
									if (highSeries.get(i) > highest)
									{
										highest = highSeries.get(i);
									}
								}
								var lowest = lowSeries.get(fromIdx);
								for( var i = fromIdx; i <= fromIdx + candleCount; i++)
								{
									if (lowSeries.get(i) < lowest)
									{
										lowest = lowSeries.get(i);
									}

								}

								if(lowest <= 0)
								{
									return false;
								}
								var normFact = (highest - lowest) / candleCount;
								var x, y, sum_x = 0, sum_y = 0, sum_xx = 0, sum_xy = 0, count = 0;
								for (var v = 0; v <=  candleCount; v++) {
									x = v;
									var idx = fromIdx + candleCount - v;
									var ohlc4 = (openSeries.get(idx) + highSeries.get(idx) 
														+ lowSeries.get(idx) + closeSeries.get(idx)) / 4;
									y = ohlc4 / normFact;
									// y = openSeries.get(idx) / normFact;
									sum_x += x;
									sum_y += y;
									sum_xx += x*x;
									sum_xy += x*y;
									count++;

									// y = closeSeries.get(idx) / normFact;
									// sum_x += x;
									// sum_y += y;
									// sum_xx += x*x;
									// sum_xy += x*y;
									// count++;
								}
							
								/*
								 * Calculate m and b for the formular:
								 * y = x * m + b
								 */
								var m = (count*sum_xy - sum_x*sum_y) / (count*sum_xx - sum_x*sum_x);
								var b = (sum_y/count) - (m*sum_x)/count;

								console.log("m:" + m  + " P1: " + b * normFact + " P2: " + (candleCount * m + b) * normFact);
								isUpTrend = m >= 0.5 && b * normFact > 0 && (candleCount * m + b) * normFact > 0;
								
								return [isUpTrend, b * normFact, (candleCount * m + b) * normFact, m];
							}

							
							this.IsDownTrend = function(fromIdx, openSeries, highSeries, lowSeries, closeSeries, candleCount) {
								var isDownTrend = false;
								
								var highest = highSeries.get(fromIdx);
								for( var i = fromIdx; i <= fromIdx + candleCount; i++)
								{
									if (highSeries.get(i) > highest)
									{
										highest = highSeries.get(i);
									}
								}
								var lowest = lowSeries.get(fromIdx);
								for( var i = fromIdx; i <= fromIdx + candleCount; i++)
								{
									if (lowSeries.get(i) < lowest)
									{
										lowest = lowSeries.get(i);
									}

								}

								if(lowest <= 0)
								{
									return false;
								}
								var normFact = (highest - lowest) / candleCount;
								var x, y, sum_x = 0, sum_y = 0, sum_xx = 0, sum_xy = 0, count = 0;
								for (var v = 0; v <=  candleCount; v++) {
									x = v;
									var idx = fromIdx + candleCount - v;
									var ohlc4 = (openSeries.get(idx) + highSeries.get(idx) 
														+ lowSeries.get(idx) + closeSeries.get(idx)) / 4;
									y = ohlc4 / normFact;
									// y = openSeries.get(idx) / normFact;
									sum_x += x;
									sum_y += y;
									sum_xx += x*x;
									sum_xy += x*y;
									count++;

									// y = closeSeries.get(idx) / normFact;
									// sum_x += x;
									// sum_y += y;
									// sum_xx += x*x;
									// sum_xy += x*y;
									// count++;
								}
							
								/*
								 * Calculate m and b for the formular:
								 * y = x * m + b
								 */
								var m = (count*sum_xy - sum_x*sum_y) / (count*sum_xx - sum_x*sum_x);
								var b = (sum_y/count) - (m*sum_x)/count;

								console.log("_Down Trend m:" + m  + " P1: " + b * normFact + " P2: " + (candleCount * m + b) * normFact);
								isDownTrend = m <= -0.45 && b * normFact > 0 && (candleCount * m + b) * normFact > 0;
								
								return [isDownTrend, b * normFact, (candleCount * m + b) * normFact, m];
							}
						
							this.getFirstPeakIdx = function(fromIdx, toIdx, highSeries, lowSeries, closeSeries, peakLeft, peakRight) {
								var PeakIdx = fromIdx
								
								var FirstPeakHH = lowSeries.get(fromIdx + peakLeft)
								for( var i = fromIdx + peakRight; i <= toIdx; i++)
								{
									if(highSeries.get(i) > FirstPeakHH)
									{
										var isPeak = true;
										var j;
										for(j=i+1; j <= i+peakLeft; j++)
										{
											if(highSeries.get(j) >= highSeries.get(i))
											{
												isPeak = false;
											}
										}
										for(j=i-peakRight; j <= i-1; j++)
										{
											if(highSeries.get(j) >= highSeries.get(i))
											{
												isPeak = false;
											}
										}

										if(isPeak)
										{
											PeakIdx = i;
											break;
										}
									}
								}
								return PeakIdx;
							};

							
							this.getFirstNearIdx = function(fromIdx, toIdx, highSeries, lowSeries, closeSeries, peakRight, highRef) {
								var PeakIdx = fromIdx
								
								for( var i = fromIdx + peakRight; i <= toIdx; i++)
								{
									if(highSeries.get(i) >= highRef)
									{
										var isPeak = true;
										var j;
										for(j=i-peakRight; j <= i-1; j++)
										{
											if(highSeries.get(j) >= highSeries.get(i))
											{
												isPeak = false;
											}
										}
										if(isPeak)
										{
											PeakIdx = i;
											break;
										}
									}
								}
								return PeakIdx;
							};

							this.getLowestLow = function(fromIdx, toIdx, highSeries, lowSeries, closeSeries) {
								var LowestIdx = fromIdx
								for( var i = fromIdx+1; i <= toIdx; i++) {
									if(lowSeries.get(LowestIdx) > lowSeries.get(i))
										LowestIdx = i;
								}
								return LowestIdx;
							};

							this.getIdxFromTimestamp = function(timeSeries, time)
							{
								for(var i=0; i < timeSeries.hist_pos; i++)
								{
									if(timeSeries.get(i) == time)
									{
										return i;
									}
								}
								return -1;
							};

							this.VerifyHandleAndRedraw = function(cacheName, timeSeries, openSeries, highSeries, lowSeries, closeSeries)
							{
								var HH1Time = this.cupAndHandleList[cacheName].HH1Time;
								var HH2Time = this.cupAndHandleList[cacheName].HH2Time;
								var LLTime = this.cupAndHandleList[cacheName].LLTime;
								var HH1_IDX = this.getIdxFromTimestamp(timeSeries, HH1Time);
								var HH2_IDX = this.getIdxFromTimestamp(timeSeries, HH2Time);
								var LL_IDX = this.getIdxFromTimestamp(timeSeries, LLTime);

								if (HH1_IDX < 0 || HH2_IDX < 0 || LL_IDX < 0)
								{
									console.error("Invalid time stored in verify handle data..");
									return;
								}

								if(this.cupAndHandleList[cacheName].IsCompleted)
									return;
								
								// Check if the handle voids the retracement limits
								var minRetracementPrice = highSeries.get(HH1_IDX) - (highSeries.get(HH1_IDX) - lowSeries.get(LL_IDX)) * this.in_5_minRetracement / 100.0;
								var maxRetracementPrice = highSeries.get(HH1_IDX) - (highSeries.get(HH1_IDX) - lowSeries.get(LL_IDX)) * this.in_6_maxRetracement / 100.0;
								var minWidth = parseInt(this.in_7_minWidth * (HH2_IDX - HH1_IDX) / 100.0);
								var maxWidth = parseInt(this.in_8_maxWidth * (HH2_IDX - HH1_IDX) / 100.0); 
								var minCondition = -1;
								var maxCondition = -1;
								
								if(HH1_IDX - maxWidth < 0)
								{
									return;
								}
									
								for(var i = HH1_IDX-1; i >= 0; i--)
								{
									// Check that price does not go below max retracement levels.
									if(maxCondition == -1 && (HH1_IDX - i) < maxWidth && closeSeries.get(i) < maxRetracementPrice)
									{
										maxCondition = i;
										break;
									}
									// Check that price goes below minRetracement atleast at once.
									if(minCondition == -1 && (HH1_IDX - i) < maxWidth && lowSeries.get(i) <= minRetracementPrice)
									{
										minCondition = i;
									}
								}

								// if(minCondition == -1)
								// {
								// 	// TODO: no min retracement in handle.
								// 	console.log("No min retracement of handle.");
								// }

								// Check if the cup and handle gets breakout
								var i=HH1_IDX;
								var breakOutAt = -1;
								for(i = HH1_IDX; i >=0; i--)
								{
									if(highSeries.get(HH1_IDX) < highSeries.get(i))
									{
										breakOutAt = i;
										break;
									}
								}

								if(i < 0)
								{
									if( HH1_IDX <= maxWidth)
									{
										// Handle is inprogress. Keep Waiting
										console.log("-------------->>>> !!!!! In Progress Handle.. Keep Waiting.... !!!!!");
									}
									else
									{
										// Handle Out of Width but no breakout.... 
										console.log(" !!!!! Handle Out of Width. No Breakout..... !!!!!");
										// TODO: Draw without breakout and remove from list.
										this.cupAndHandleList[cacheName].IsCompleted = true;

									}
								}

								var breakOutInLimit = false;
								var earlyBreakout = false;
								if(HH1_IDX - breakOutAt >= minWidth && HH1_IDX - i <= maxWidth)
								{
									// Breakout in the Limit; Record the Cup and Handle with Breakout.
									// TODO: Draw with breakout and remove from list
									console.log("Breakout in the Limit:" + timeSeries.get(i));
									this.cupAndHandleList[cacheName].IsCompleted = true;
									breakOutInLimit = true;
								}
								else if(HH1_IDX - i < minWidth)
								{
									// Breakout is out of limit; Invalidate the Cup and Handle.
									// TODO: Draw Invalid Cup and Handle due to Early Breakout.
									console.log("Early Breakout:" + timeSeries.get(i));
									this.cupAndHandleList[cacheName].IsCompleted = true;
									earlyBreakout = true;
								}
								else if(i >= 0)
								{
									console.log("Late Breakout:" + timeSeries.get(i));
									this.cupAndHandleList[cacheName].IsCompleted = true;
								}

								var validHandle = false;
								// Breakout in limit then check for retracement till breakout. 
								if(breakOutInLimit)
								{
									if(minCondition >= breakOutAt 
										&& (maxCondition == -1 || maxCondition < breakOutAt))
									{
										validHandle = true;
									}
								}
								else if(!earlyBreakout)
								{
									// If no breakout, then check retracement till max
									
									if(minCondition >= (HH1_IDX - minWidth)
										&& (maxCondition == -1 || maxCondition < (HH1_IDX - minWidth)))
									{
										validHandle = true;
									}
								}

								if(this.cupAndHandleList[cacheName].IsCompleted)
								{
									Object.values(this.cupAndHandleList[cacheName].HandleDrawings).forEach(val => {
										window.tvWidget.activeChart().removeEntity(val);
									});
									this.cupAndHandleList[cacheName].HandleDrawings = [];

									var id;
									if(this.in_3)
									{
										// Min Retracement Line
										id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH1_IDX)/1000, price: minRetracementPrice},
											{time: timeSeries.get(HH1_IDX - maxWidth)/1000, price: minRetracementPrice},
											], //drawingShape,
											{
												shape: "trend_line",
												lock: true,
												disableSelection: true,
												disableSave: true,
												disableUndo: true,
												text: "text",
												overrides: {
													linewidth: 2,
													linecolor: (breakOutInLimit && minCondition >= breakOutAt) || (minCondition >= (HH1_IDX - minWidth))
														? "#0288d1" : "#aa0000"
												}
											}
										);
										this.cupAndHandleList[cacheName].HandleDrawings.push(id);
										
										// Max Retracement Line
										id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH1_IDX)/1000, price: maxRetracementPrice},
											{time: timeSeries.get(HH1_IDX - maxWidth)/1000, price: maxRetracementPrice},
											], //drawingShape,
											{
												shape: "trend_line",
												lock: true,
												disableSelection: true,
												disableSave: true,
												disableUndo: true,
												text: "text",
												overrides: {
													linewidth: 2,
													linecolor: (breakOutInLimit && maxCondition < breakOutAt) || (maxCondition < (HH1_IDX - minWidth)) 
														? "#0288d1" : "#aa0000"
												}
											}
										);
										this.cupAndHandleList[cacheName].HandleDrawings.push(id);

										// Middle Line
										id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH1_IDX - minWidth)/1000, price: minRetracementPrice},
											{time: timeSeries.get(HH1_IDX - minWidth)/1000, price: maxRetracementPrice},
											], //drawingShape,
											{
												shape: "trend_line",
												lock: true,
												disableSelection: true,
												disableSave: true,
												disableUndo: true,
												text: "text",
												overrides: {
													linewidth: 1,
													linecolor: "#0288d1"
												}
											}
										);
										this.cupAndHandleList[cacheName].HandleDrawings.push(id);

										// Breakout line
										id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH1_IDX)/1000, price: highSeries.get(HH1_IDX)},
											{time: timeSeries.get(HH1_IDX - maxWidth)/1000, price: highSeries.get(HH1_IDX)},
											], //drawingShape,
											{
												shape: "trend_line",
												lock: true,
												disableSelection: true,
												disableSave: true,
												disableUndo: true,
												text: "text",
												overrides: {
													linewidth: 1,
													linecolor: earlyBreakout ? "#aa0000" : breakOutInLimit ? "#119911": "#0288d1"
												}
											}
										);
										this.cupAndHandleList[cacheName].HandleDrawings.push(id);
									}

									// Draw Rectangle of grey color.
									id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH1_IDX)/1000, price: highSeries.get(HH1_IDX)},
										{time: timeSeries.get(HH1_IDX - maxWidth)/1000, price: maxRetracementPrice},
										], //drawingShape,
										{
											shape: "rectangle",
											lock: true,
											disableSelection: true,
											disableSave: true,
											disableUndo: true,
											// ownerStudyId: "CupNHandle@tv-basicstudies-1"
											overrides: {
												backgroundColor: '#000000',
												transparency: 85,
												linewidth: 0
											}
										}
									);
									this.cupAndHandleList[cacheName].HandleDrawings.push(id);
								}

								if(this.in_9_drawOnlyValid && !validHandle && this.cupAndHandleList[cacheName].IsCompleted)
								{
									Object.values(this.cupAndHandleList[cacheName].HandleDrawings).forEach(val => {
										window.tvWidget.activeChart().removeEntity(val);
									});
									this.cupAndHandleList[cacheName].HandleDrawings = [];

									Object.values(this.cupAndHandleList[cacheName].CupDrawings).forEach(val => {
										window.tvWidget.activeChart().removeEntity(val);
									});
									this.cupAndHandleList[cacheName].CupDrawings = [];
								}
								

							};

							this.VerifyAllHandleAndUpdate = function(timeSeries, openSeries, highSeries, lowSeries, closeSeries)
							{
								Object.keys(this.cupAndHandleList).forEach(key => {
									// Check all pending Cup and Handle for void or breakout
									if(!this.cupAndHandleList[key].IsCompleted)
										this.VerifyHandleAndRedraw(key, timeSeries, openSeries, highSeries, lowSeries, closeSeries);

								});
							};

							this.drawingCache = {};
							this.cupAndHandleList = {};
							this.in_0 = undefined;
							this.in_1 = undefined;
							this.in_2 = undefined;
							this.in_3 = undefined;
							this.in_4 = undefined;
							this.in_5_minRetracement = undefined;
							this.in_6_maxRetracement = undefined;
							this.in_7_minWidth = undefined;
							this.in_8_maxWidth = undefined;
							this.in_9_drawOnlyValid = undefined;
							this.in_10_Cup2 = undefined;
							this.interval = undefined;
							this.main = function(context, inputCallback) {
								this._context = context;

								//Remove all shapes.
								if(this.in_0 !== inputCallback(0) || this.in_1 !== inputCallback(1) || this.in_2 !== inputCallback(2)
									|| this.in_3 !== inputCallback(3) || this.in_4 !== inputCallback(4)
									|| this.in_5_minRetracement !== inputCallback(5) || this.in_6_maxRetracement !== inputCallback(6)
									|| this.in_7_minWidth !== inputCallback(7) || this.in_8_maxWidth !== inputCallback(8)
									|| this.in_9_drawOnlyValid !== inputCallback(9)
									|| this.in_10_Cup2 !== inputCallback(10)
                                    || this.interval != context.symbol.interval)
								{
									Object.keys(this.cupAndHandleList).forEach(key => {
										Object.values(this.cupAndHandleList[key].CupDrawings).forEach(val => {
												window.tvWidget.activeChart().removeEntity(val);
										});
										Object.values(this.cupAndHandleList[key].HandleDrawings).forEach(val => {
												window.tvWidget.activeChart().removeEntity(val);
										});
									});
									this.in_0 = inputCallback(0);
									this.in_1 = inputCallback(1);
									this.in_2 = inputCallback(2);
									this.in_3 = inputCallback(3);
									this.in_4 = inputCallback(4);
									this.in_5_minRetracement = inputCallback(5);
									this.in_6_maxRetracement = inputCallback(6);
									this.in_7_minWidth = inputCallback(7);
									this.in_8_maxWidth = inputCallback(8);
									this.in_9_drawOnlyValid = inputCallback(9);
									this.in_10_Cup2 = inputCallback(10);
                                    this.interval = context.symbol.interval;
									this.drawingCache = {};
									this.cupAndHandleList = {};
								}
								this._input = inputCallback;
								this._context.select_sym(1);

								var open = PineJS.Std.open(this._context);
								var close = PineJS.Std.close(this._context);
								var low = PineJS.Std.low(this._context);
								var high = PineJS.Std.high(this._context);
								var time = PineJS.Std.time(this._context);
								var closeSeries = this._context.new_unlimited_var(close);
								var lowSeries = this._context.new_unlimited_var(low);
								var openSeries = this._context.new_unlimited_var(open);
								var highSeries = this._context.new_unlimited_var(high);
								var timeSeries = this._context.new_unlimited_var(time);
					
								

								for(var twoPeaks=0; twoPeaks < 1; twoPeaks++)
								{
									var HH1_IDX = this.getFirstPeakIdx(1, this._input(1), highSeries, lowSeries, closeSeries, 4, 4);
									var HH2_IDX = this.getFirstNearIdx(HH1_IDX, HH1_IDX + this._input(1), highSeries, lowSeries, closeSeries, 4, highSeries.get(HH1_IDX));
									
									var LastHH2_IDX = -1;

									if(twoPeaks == 0 || HH2_IDX == HH1_IDX)
									{
										// First try to find using peak
										HH2_IDX = this.getFirstPeakIdx(HH1_IDX, HH1_IDX + this._input(1), highSeries, lowSeries, closeSeries, 4, 4);
									}
									else
									{
										// Second time in for loop try to find using near peak.
										LastHH2_IDX = HH2_IDX;
										console.log("Peak found using Near..");
									}
									var LL_IDX = this.getLowestLow(HH1_IDX, HH2_IDX, highSeries, lowSeries, closeSeries);

									var HH = Math.max(highSeries.get(HH1_IDX), highSeries.get(HH2_IDX));
									var LL = lowSeries.get(LL_IDX);

									// check for peak near to HH1
									while(LastHH2_IDX != HH2_IDX && HH2_IDX != HH1_IDX 
										&& Math.abs(highSeries.get(HH1_IDX) - highSeries.get(HH2_IDX)) / (HH-LL) > this._input(2))
									{
										LastHH2_IDX = HH2_IDX;
										HH2_IDX = this.getFirstPeakIdx(HH2_IDX, HH1_IDX + this._input(1), highSeries, lowSeries, closeSeries, 4, 4);
										LL_IDX = this.getLowestLow(HH1_IDX, HH2_IDX, highSeries, lowSeries, closeSeries);

										HH = Math.max(highSeries.get(HH1_IDX), highSeries.get(HH2_IDX));
										LL = lowSeries.get(LL_IDX);
									}

									if(HH2_IDX - HH1_IDX <= this._input(0))
									{
										console.log("No minimum distance between peak: " + new Date(timeSeries.get(HH1_IDX)) 
											+ " To: " + new Date(timeSeries.get(HH2_IDX))
											+ " At: " + new Date(timeSeries.get(0)));
										return NaN;
									}
									
									console.log("+ Minimum distance Found Between peak: " + new Date(timeSeries.get(HH1_IDX)) 
									+ " To: " + new Date(timeSeries.get(HH2_IDX))
									+ " At: " + new Date(timeSeries.get(0)));
									
									var cacheName = "" + timeSeries.get(HH2_IDX) + timeSeries.get(HH1_IDX)/1000 + timeSeries.get(LL_IDX)/1000 + this._context.symbol.name;
									
									// Check if HH1, HH2 & LL are already Completed Cup & Handle
									if(this.drawingCache[cacheName] != undefined)
									{
										return NaN;
									}

									var Found = false;
									// Check Pending Cup & Handle
									if(this.cupAndHandleList[cacheName] != undefined)
									{
										// Check and update all pending handles
										this.VerifyAllHandleAndUpdate(timeSeries, openSeries, highSeries, lowSeries, closeSeries);
										// This cup and handle is already detected and stored for verification.
										return NaN;
									}
									else
									{
										// Check and update all pending handles.
										this.VerifyAllHandleAndUpdate(timeSeries, openSeries, highSeries, lowSeries, closeSeries);
									}


									if(Math.abs(highSeries.get(HH1_IDX) - highSeries.get(HH2_IDX)) / (HH-LL) >= this._input(2))
									{
										console.log("-- Peaks not at similar level: " + new Date(timeSeries.get(HH1_IDX)) 
											+ " To: " + new Date(timeSeries.get(HH2_IDX))
											+ " At: " + new Date(timeSeries.get(0)));
										return NaN;
									}

									var isUpTrend = false;
									var isUpTrendData;
									isUpTrendData = this.IsUpTrend(HH2_IDX, openSeries, highSeries, lowSeries, closeSeries, inputCallback(4));
									isUpTrend = isUpTrendData[0];
									if(!isUpTrend)
									{
										console.log("-- Not UpTrend: " + new Date(timeSeries.get(HH1_IDX)) 
										+ " To: " + new Date(timeSeries.get(HH2_IDX))
										+ " At: " + new Date(timeSeries.get(0)));
										return NaN;
									}
									
									console.log("++ Peaks at similar level: " + new Date(timeSeries.get(HH1_IDX)) 
									+ " To: " + new Date(timeSeries.get(HH2_IDX))
									+ " At: " + new Date(timeSeries.get(0)));

									var CupSize = HH2_IDX - HH1_IDX
									var HighPrice = Math.max(highSeries.get(HH1_IDX), highSeries.get(HH2_IDX));
									var LowPrice = HighPrice - (HighPrice - lowSeries.get(LL_IDX)) * 0.3; //0.15//0.3 // Yello and Orange
									var TopX1 = parseInt(HH1_IDX + CupSize * 0.2);
									var TopX2 = parseInt(HH1_IDX + CupSize * 0.4);
									var TopX3 = parseInt(HH1_IDX + CupSize * 0.6);
									var TopX4 = parseInt(HH1_IDX + CupSize * 0.8);


									var GreenHigh = LL + (HH - LL) * 0.32//0.45//0.32 // 60% of second last block
									var GreenLow = LL + (HH - LL) * 0.2
									var GreenX1 = HH1_IDX;
									var GreenX2 = parseInt(HH1_IDX + CupSize * 0.2);
									var GreenX3 = parseInt(HH1_IDX + CupSize * 0.8);
									var GreenX4 = parseInt(HH1_IDX + CupSize);

									var BlueHigh = LL + (HH-LL) * 0.12//0.25//0.12 // 60% of last block
									var BlueLow = LL ;
									var BlueX1 = parseInt(HH1_IDX + CupSize * 0.2);
									var BlueX2 = parseInt(HH1_IDX + CupSize * 0.4);
									var BlueX3 = parseInt(HH1_IDX + CupSize * 0.6);
									var BlueX4 = parseInt(HH1_IDX + CupSize * 0.8);
									
									if( HH2_IDX - HH1_IDX > this._input(0) && Math.abs(highSeries.get(HH1_IDX) - highSeries.get(HH2_IDX)) / (HH-LL) < this._input(2)) {
										// Check for middle shape: middle top 6 blocks of 5x5 matric should be empty.
										// Reject if price is upper blocks
										var FoundTop = true
										var i;
										for(i = TopX2; i <= TopX3; i++) {
											if(highSeries.get(i) > LowPrice) {
												FoundTop = false
												break;
											}
										}

										for(i = TopX1; i <= TopX2; i++) {
											if(closeSeries.get(i) > LowPrice) {
												FoundTop = false
												break;
											}
										}

										for(i = TopX3; i <= TopX4; i++) {
											if(closeSeries.get(i) > LowPrice) {
												FoundTop = false
												break;
											}
										}
											
										// Accept only if low both side in both blue or both gree blocks
										
										var FoundGLeft = false 
										var FoundGRight = false 

										for(i = GreenX1; i <= GreenX2; i++) {
											if(lowSeries.get(i) <= GreenHigh && lowSeries.get(i) >= GreenLow) {
												FoundGRight = true
												break
											}
										}
										for(i = GreenX3; i < GreenX4; i++) {
											if(lowSeries.get(i) <= GreenHigh && lowSeries.get(i) >= GreenLow) {
												FoundGLeft = true;
												break;
											}
										}
										
										
										var FoundBLeft = false 
										var FoundBRight = false 

										for(i = BlueX1; i <= BlueX2; i++) {
											if( lowSeries.get(i) <= BlueHigh && lowSeries.get(i) >= BlueLow) {
												FoundBRight = true
												break
											}
										}
										for(i = BlueX3; i <= BlueX4; i++) {
											if(lowSeries.get(i) <= BlueHigh && lowSeries.get(i) >= BlueLow) {
												FoundBLeft = true
												break
											}
										}
										
										// check if high 2 is greater.
										// check if low is in middle: i.e. between 30-70 %
										var LowIsInCenter = (LL_IDX - HH1_IDX) / (HH2_IDX - HH1_IDX) > 0.2 && (LL_IDX - HH1_IDX) / (HH2_IDX - HH1_IDX) < 0.8;
										Found = FoundTop 
										//&& ((FoundGRight && FoundGLeft) || (FoundBRight && FoundBLeft)) 
										&& highSeries.get(HH1_IDX) >= high 
										&& LowIsInCenter
										
										var isCup2 = false;
										var isDownTrendCupData = undefined;
										var isUpTrendCupData = undefined;
										if(!Found && this.in_10_Cup2)
										{
											console.log(">>>>>> Trying to identify cup using Cup 2 method... <<<<<<");
											isUpTrendCupData = this.IsUpTrend(HH1_IDX, openSeries, highSeries, lowSeries, closeSeries, LL_IDX - HH1_IDX);
											isDownTrendCupData = this.IsDownTrend(LL_IDX, openSeries, highSeries, lowSeries, closeSeries, HH2_IDX - LL_IDX);

											// Check for no high higher then HH1 or HH2
											var highCrossed = false;
											for(var i=HH1_IDX + 1; i <= LL_IDX; i++)
											{
												if(highSeries.get(i) > highSeries.get(HH1_IDX))
												{
													highCrossed = true;
													console.log(">>>>> High crossed at " + new Date(timeSeries.get(i)));
													break;
												}
											}
											if(!highCrossed && isUpTrendCupData[0] && isDownTrendCupData[0])
											{
												Found = true;
												isCup2 = true;
												console.log(">>>>>>>>>>>>>>> Cup2 Found: " + new Date(timeSeries.get(HH1_IDX)) 
												+ " To: " + new Date(timeSeries.get(HH2_IDX))
												+ " At: " + new Date(timeSeries.get(0)));
											}
										}


										if(Found)
										{
											console.log(new Date(PineJS.Std.time(this._context)));
											console.log("+++++ Cup Found at Up Trend: " + new Date(timeSeries.get(HH1_IDX)) 
											+ " To: " + new Date(timeSeries.get(HH2_IDX))
											+ " At: " + new Date(timeSeries.get(0)));
										}
										else
										{
											console.log("----- Cup Not Found: " + new Date(timeSeries.get(HH1_IDX)) 
												+ " To: " + new Date(timeSeries.get(HH2_IDX))
												+ " At: " + new Date(timeSeries.get(0)) 
												+ " FoundTop: " + (FoundTop ? 1 : 0)
												+ " HighCheck: " + (highSeries.get(HH1_IDX) >= high ? 1 : 0)
												+ " LowIsInCenter: " + (LowIsInCenter ? 1 : 0));

											// Try for 2nd Peak.
											continue;
											//return NaN;
										}
										// else
										// {
										// 	console.log(FoundTop);
										// 	console.log(FoundGRight);
										// 	console.log(FoundGLeft);
										// 	console.log(FoundBRight);
										// 	console.log(FoundBLeft);
										// 	console.log(FoundTop);
										// }
									}

									// if(Found && (HH1_IDX != HH1_IDX[1] + 1 || HH2_IDX != HH2_IDX[1] + 1)) {
									// 	line.new(x1=bar_index[HH2_IDX], y1=high[HH2_IDX], x2=bar_index[LL_IDX], y2=low[LL_IDX], color=color.red)
									// 	line.new(x1=bar_index[HH1_IDX], y1=high[HH1_IDX], x2=bar_index[LL_IDX], y2=low[LL_IDX], color=color.red)
									// 	line.new(x1=bar_index[HH1_IDX], y1=high[HH1_IDX], x2=bar_index[HH1_IDX-5], y2=high[HH1_IDX-5], color=color.red)
									// }

									var bars = { "bars" : []};
									if(Found)
									{
										// var drawingShape = [];
										// for(i = 0; i <= HH2_IDX; i++)
										// {
										// 	var barValue = [{"value": HH, "offset": -i}, {"value": LL, "offset": -i}];
										// 	bars["bars"].push(barValue);
										// 	drawingShape.push({ time: timeSeries.get(i), price: highSeries.get(i) });
										// }

										if(this.cupAndHandleList[cacheName] == undefined)
										{
											// Add to pending list 
											var pendingHandle = {};
											pendingHandle.HH1Time = timeSeries.get(HH1_IDX);
											pendingHandle.HH2Time = timeSeries.get(HH2_IDX);
											pendingHandle.LLTime = timeSeries.get(LL_IDX);
											pendingHandle.CupDrawings = [];
											pendingHandle.HandleDrawings = [];
											pendingHandle.IsCompleted = false;
											this.cupAndHandleList[cacheName] = pendingHandle;

											// Draw Identified Cup 
											{
												var arrShapes = [];
												var id = undefined;
												
												id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH1_IDX)/1000, price: HH/*highSeries.get(HH1_IDX)*/},
													{time: timeSeries.get(HH2_IDX)/ 1000, price: HH/*highSeries.get(HH2_IDX)*/},
													{time: timeSeries.get(LL_IDX)/1000, price: lowSeries.get(LL_IDX)},
													], //drawingShape,
													{
														shape: "arc",
														lock: true,
														disableSelection: true,
														disableSave: true,
														disableUndo: true,
														text: "text",
														zOrder: "bottom",
														// ownerStudyId: "CupNHandle@tv-basicstudies-1"
														overrides: {
															color: "#aa000000",
															backgroundColor: "#000000",
															lineColor: "#aa000000",
															transparency: 85,
															lineWidth: 0
														}
													}
												);
												arrShapes.push(id);

												if(this.in_3)
												{
													if(!isCup2)
													{
														// Top Rectangles..
														id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(TopX2)/1000, price: HighPrice},
															{time: timeSeries.get(TopX3)/ 1000, price: LowPrice},
															], //drawingShape,
															{
																shape: "rectangle",
																lock: true,
																disableSelection: true,
																disableSave: true,
																disableUndo: true,
																// ownerStudyId: "CupNHandle@tv-basicstudies-1"
																overrides: {
																	backgroundColor: '#ff6a00'
																}
															}
														);
														arrShapes.push(id);
														id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(TopX1)/1000, price: HighPrice},
															{time: timeSeries.get(TopX2)/ 1000, price: LowPrice},
															], //drawingShape,
															{
																shape: "rectangle",
																lock: true,
																disableSelection: true,
																disableSave: true,
																disableUndo: true,
																// ownerStudyId: "CupNHandle@tv-basicstudies-1"
																overrides: {
																	backgroundColor: '#edfc04'
																}
															}
														);
														arrShapes.push(id);
														id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(TopX3)/1000, price: HighPrice},
															{time: timeSeries.get(TopX4)/ 1000, price: LowPrice},
															], //drawingShape,
															{
																shape: "rectangle",
																lock: true,
																disableSelection: true,
																disableSave: true,
																disableUndo: true,
																// ownerStudyId: "CupNHandle@tv-basicstudies-1"
																overrides: {
																	backgroundColor: '#edfc04'
																}
															}
														);
														arrShapes.push(id);
														
														// Green Rects
														// id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(GreenX1)/1000, price: GreenHigh},
														// 	{time: timeSeries.get(GreenX2)/ 1000, price: GreenLow},
														// 	], //drawingShape,
														// 	{
														// 		shape: "rectangle",
														// 		lock: true,
														// 		disableSelection: true,
														// 		disableSave: true,
														// 		disableUndo: true,
														// 		// ownerStudyId: "CupNHandle@tv-basicstudies-1"
														// 		overrides: {
														// 			backgroundColor: '#04ff04'
														// 		}
														// 	}
														// );
														// arrShapes.push(id);
														// id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(GreenX3)/1000, price: GreenHigh},
														// 	{time: timeSeries.get(GreenX4)/ 1000, price: GreenLow},
														// 	], //drawingShape,
														// 	{
														// 		shape: "rectangle",
														// 		lock: true,
														// 		disableSelection: true,
														// 		disableSave: true,
														// 		disableUndo: true,
														// 		// ownerStudyId: "CupNHandle@tv-basicstudies-1"
														// 		overrides: {
														// 			backgroundColor: '#04ff04'
														// 		}
														// 	}
														// );
														// arrShapes.push(id);

														// // Blue Rects
														// id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(BlueX1)/1000, price: BlueHigh},
														// 	{time: timeSeries.get(BlueX2)/ 1000, price: BlueLow},
														// 	], //drawingShape,
														// 	{
														// 		shape: "rectangle",
														// 		lock: true,
														// 		disableSelection: true,
														// 		disableSave: true,
														// 		disableUndo: true,
														// 		// ownerStudyId: "CupNHandle@tv-basicstudies-1"
														// 		overrides: {
														// 			backgroundColor: '#0404ff'
														// 		}
														// 	}
														// );
														// arrShapes.push(id);
														// id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(BlueX3)/1000, price: BlueHigh},
														// 	{time: timeSeries.get(BlueX4)/ 1000, price: BlueLow},
														// 	], //drawingShape,
														// 	{
														// 		shape: "rectangle",
														// 		lock: true,
														// 		disableSelection: true,
														// 		disableSave: true,
														// 		disableUndo: true,
														// 		// ownerStudyId: "CupNHandle@tv-basicstudies-1"
														// 		overrides: {
														// 			backgroundColor: '#0404ff'
														// 		}
														// 	}
														// );
														// arrShapes.push(id);
													}
													else
													{
														id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH1_IDX)/1000, price: isUpTrendCupData[2]},
															{time: timeSeries.get(LL_IDX)/ 1000, price: isUpTrendCupData[1]},
															], //drawingShape,
															{
																shape: "trend_line",
																lock: true,
																disableSelection: true,
																disableSave: true,
																disableUndo: true,
																// ownerStudyId: "CupNHandle@tv-basicstudies-1"
																overrides: {
																	linewidth: 2,
																	linecolor: '#04ff04'
																}
															}
														);
														arrShapes.push(id);
														
														id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(LL_IDX)/1000, price: isDownTrendCupData[2]},
															{time: timeSeries.get(HH2_IDX)/ 1000, price: isDownTrendCupData[1]},
															], //drawingShape,
															{
																shape: "trend_line",
																lock: true,
																disableSelection: true,
																disableSave: true,
																disableUndo: true,
																// ownerStudyId: "CupNHandle@tv-basicstudies-1"
																overrides: {
																	linewidth: 2,
																	linecolor: '#04ff04'
																}
															}
														);
														arrShapes.push(id);
													}

													id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH2_IDX)/1000, price: isUpTrendData[2]},
														{time: timeSeries.get(HH2_IDX + inputCallback(4) - 1)/ 1000, price: isUpTrendData[1]},
														], //drawingShape,
														{
															shape: "trend_line",
															lock: true,
															disableSelection: true,
															disableSave: true,
															disableUndo: true,
															// ownerStudyId: "CupNHandle@tv-basicstudies-1"
															overrides: {
																linewidth: 2,
																linecolor: '#ff0404'
															}
														}
													);
													arrShapes.push(id);
												}
												// id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH1_IDX)/1000, price: highSeries.get(HH1_IDX)},
												// 	// {time: timeSeries.get(HH2_IDX), price: highSeries.get(HH2_IDX)},
												// 	{time: timeSeries.get(HH2_IDX)/1000, price: highSeries.get(HH2_IDX)},
												// 	], //drawingShape,
												// 	{
												// 		shape: "trend_line",
												// 		lock: true,
												// 		disableSelection: true,
												// 		disableSave: true,
												// 		disableUndo: true,
												// 		text: "text",
												// 		overrides: {
												// 			linewidth: 1,
												// 			linecolor: "#99991588"
												// 		}
												// 		// ownerStudyId: "CupNHandle@tv-basicstudies-1"
												// 	}
												// );
												// arrShapes.push(id);

												id = window.tvWidget.activeChart().createMultipointShape( [{time: timeSeries.get(HH1_IDX)/1000, price: highSeries.get(HH1_IDX)},
													// {time: timeSeries.get(HH2_IDX), price: highSeries.get(HH2_IDX)},
													{time: timeSeries.get(HH1_IDX - 5)/1000, price: highSeries.get(HH1_IDX - 5)},
													], //drawingShape,
													{
														shape: "trend_line",
														lock: true,
														disableSelection: true,
														disableSave: true,
														disableUndo: true,
														text: "text",
														overrides: {
															linewidth: 10,
															linecolor: "#99991588"
														}
														// ownerStudyId: "CupNHandle@tv-basicstudies-1"
													}
												);
												this.cupAndHandleList[cacheName].HandleDrawings.push(id);

												this.cupAndHandleList[cacheName].CupDrawings = arrShapes;
											}

											// Verify Handle
											this.VerifyHandleAndRedraw(cacheName, timeSeries, openSeries, highSeries, lowSeries, closeSeries)
										}
										else
										{
											console.error("Should not come here must be handled at begining of main: " + cacheName );
											return NaN;
										}
										// Break from 2nd handle.
										break;
									}
									else
									{
										var barValue = [{"value": NaN, "offset": 0}, {"value": NaN, "offset": 0}];
											bars["bars"].push(barValue);
									}
								}
								return NaN;
								// return Found ? bars : bars;
							}
						
						}
					}
					,{
						name: "GRaB",
						metainfo: {
							"_metainfoVersion": 40,
							"id": "GRaB@tv-basicstudies-1",
							"scriptIdPart": "",
							"name": "GRaB",
							"description": "GRaB",
							"shortDescription": "GRaB",
					
							"is_hidden_study": true,
							"is_price_study": true,
							"isCustomIndicator": true,
					
							"plots": [{"id": "plot_0", "type": "line"}, 
										{"id": "plot_1", "type": "line"}, 
										{"id": "plot_2", "type": "line"}],
							
							"defaults": {
								
								"styles": {
									plot_0:
									{
										linestyle: 0,
										visible: true,
										linewidth: 1,
										plottype: 2,
										trackPrice: false,
										transparency: 40,
										color: "green"
									},
									plot_1:
									{
										linestyle: 1,
										visible: true,
										linewidth: 1,
										plottype: 2,
										trackPrice: false,
										transparency: 40,
										color: "blue"
									},
									plot_2:
									{
										linestyle: 1,
										visible: true,
										linewidth: 1,
										plottype: 2,
										trackPrice: false,
										transparency: 40,
										color: "red"
									},
								},
					
								// Precision is set to one digit, e.g. 777.7
								"precision": 1,
					
								"inputs": {
									in_0: 34
								}
							},
							"styles": {
								"plot_0": {
									// Output name will be displayed in the Style window
									"title": "High",
									"histogramBase": 0,
								},
								"plot_1": {
									// Output name will be displayed in the Style window
									"title": "Close",
									"histogramBase": 0,
								},
								"plot_2": {
									// Output name will be displayed in the Style window
									"title": "Low",
									"histogramBase": 0,
								}
							},
							"inputs": [{
									id: "in_0",
									name: "Period",
									defval: 34,
									type: "integer",
									min: 1,
									max: 1e4
								}],
						},
					
						constructor: function() {
							this.init = function(context, inputCallback) {
								this._context = context;
								this._input = inputCallback;
					
								var symbol = PineJS.Std.ticker(this._context);
								symbol = symbol.replace("}", "")
								symbol = symbol.replaceAll("\"", "")

								this._context.new_sym(symbol, PineJS.Std.period(this._context), PineJS.Std.period(this._context));
							};
					
							this.main = function(context, inputCallback) {
								this._context = context;

								var close = PineJS.Std.close(this._context);
								var low = PineJS.Std.low(this._context);
								var high = PineJS.Std.high(this._context);
								var time = PineJS.Std.time(this._context);
								var closeSeries = this._context.new_var(PineJS.Std.close(this._context));//this._context.new_unlimited_var(close);
								var lowSeries = this._context.new_var(PineJS.Std.low(this._context));//this._context.new_unlimited_var(low);
								var highSeries = this._context.new_var(PineJS.Std.high(this._context)); // this._context.new_unlimited_var(high);
								//var timeSeries = this._context.new_unlimited_var(time);
								
								
								var emaHigh = PineJS.Std.ema(highSeries, inputCallback(0), this._context);
								var emaClose = PineJS.Std.ema(closeSeries, inputCallback(0), this._context);
								var emaLow = PineJS.Std.ema(lowSeries, inputCallback(0), this._context);

								
								return highSeries.hist_pos >= inputCallback(0) -1 ? [emaHigh, emaClose, emaLow] : [NaN, NaN, NaN];
								// return Found ? bars : bars;
							}
						}
					}
					
				]);
			},
		};
		const tvWidget = new window.TradingView.widget(widgetOptions);
		// const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;
		window.tvWidget = tvWidget;
		tvWidget.onChartReady(() => {
			if (query_params.get('date')) {
				var goto_date = query_params.get('date')
				try {
					var dateObj = new Date(goto_date)
					if (dateObj) {
						var fromTime = Math.round(dateObj.getTime() / 1000)
						var to = fromTime + (86400 * 3) // seconds of a 3 day
						tvWidget.activeChart().setVisibleRange({ 'from': fromTime, 'to': to }, {})
					}
				} catch(err) {
					console.error(err)
				}
			}
			if (query_params.get('candle')) {
				var chart_type = query_params.get('candle').toLowerCase()
				if (this.supported_chart_types[chart_type] || this.supported_chart_types[chart_type] == 0) {
					tvWidget.activeChart().setChartType(this.supported_chart_types[chart_type])
				}
			}
			tvWidget.headerReady().then(() => {
				console.log('header ready')
			});
			tvWidget.chart().createStudy('CupNHandle');
			//tvWidget.chart().createStudy('GRaB', false, true);
		});
	}

	componentWillUnmount() {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	render() {
		return (
			<div
				id={ this.props.containerId }
				className={ 'TVChartContainer' }
			/>
		);
	}
}
