var url = 'https://slopeindex.silentinfotech.com' // url without tailing slash('/')
var db_name = 'master'

export const session_string = 'session_id'
export const active_user_string = 'active_user'

function buildRequest(params) {
  return JSON.stringify({
    jsonrpc: "2.0",
    method: "call",
    params: params, // payload
  });
}

function handleOdooErrors(response) {
  if (!response.error) {
    return response.result;
  }

  let error = response.error;
  let errorObj = {
    title: "    ",
    message: "",
    fullTrace: error
  };

  if (error.code === 200 && error.message === "Odoo Server Error" && error.data.name === "werkzeug.exceptions.NotFound") {
    errorObj.title = "page_not_found";
    errorObj.message = "HTTP Error";
  } else if (error.code === 200 && error.message === "Odoo Server Error" && (error.data.name === "builtins.ValueError" || error.data.name === "odoo.exceptions.AccessDenied")) {
    errorObj.title = "authentication failed";
    errorObj.message = error.data.message;
  }
  else if ((error.code === 100 && error.message === "Odoo Session Expired") || // v8
    (error.code === 300 && error.message === "OpenERP WebClient Error" && error.data.debug.match("SessionExpiredException")) // v7
  ) {
    window.location.href="/trading/login"
    cleanUpInfo()
    alert('Session Expired')
    // return Promise.reject('Session Expired')
  } else if ((error.message === "Odoo Server Error" && /FATAL: database "(.+)" does not exist/.test(error.data.message))) {
    errorObj.title = "database_not_found";
    errorObj.message = error.data.message;
  } else if ((error.data.name === "openerp.exceptions.AccessError")) {
    errorObj.title = "AccessError";
    errorObj.message = error.data.message;
  } else {
    let split = ("" + error.data.fault_code).split("\n")[0].split(" -- ");
    if (split.length > 1) {
      error.type = split.shift();
      error.data.fault_code = error.data.fault_code.substr(error.type.length + 4);
    }

    if (error.code === 200 && error.type) {
      errorObj.title = error.type;
      errorObj.message = error.data.fault_code.replace(/\n/g, "<br />");
    } else {
      errorObj.title = error.message;
      errorObj.message = error.data.debug.replace(/\n/g, "<br />");
    }
  }
  return Promise.reject(errorObj);
}

export async function makeApiRequest(path, params = {}) {
  try {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'X-Openerp-Session-Id': localStorage.getItem(session_string) || 'a525fa79291b50334444fc837b8b4cacb8064134',
      }
      var response = await fetch(`${url}/${path}`, {
        method: 'POST',
        headers: headers,
        body: buildRequest(params)
      });
      response = await response.json()
      if (response.result && response.result.session_id) {
        localStorage.setItem(session_string, response.result.session_id)
      }
      return handleOdooErrors(response);
    } catch (err) {
      console.log(err)
      return Promise.reject('Something went wrong')
    }
  } catch (error) {
    console.log(error)
    throw new Error(`Api request error: ${error}`);
  }
}

export function isLoggedIn() {
  return localStorage.getItem(session_string) ? true : false
}

export function logOut() {
  cleanUpInfo()
  return true
}

export function cleanUpInfo () { 
  localStorage.removeItem(session_string)
  localStorage.removeItem(active_user_string)
}

export default async function authenticate(username, password) {
  let params = {
    db: db_name,
    login: username,
    password: password
  };
  try {
    var resp = await makeApiRequest('web/session/authenticate', params)
    localStorage.setItem(active_user_string, JSON.stringify(resp))
    return resp
  } catch(err) {
    console.log(err)
    throw err
  }
}
