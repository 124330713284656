import { makeApiRequest } from '../Helpers/odoo-rpc.helper.js';

export default {
    getAllCharts: function() {
        return new Promise((resolve, reject) => {
            makeApiRequest('charts').then(data => {
                console.log(data);
                resolve(data);
            }).catch(err => {
                console.log(err);
                reject();
            })
        })
    },

    removeChart: function(chartId) {
        return new Promise((resolve, reject) => {
            makeApiRequest('removechart', { chartId: parseInt(chartId) }).then(() => {
                resolve();
            }).catch(err => {
                console.log(err);
                reject();
            })
        })
    },

    saveChart: function(chartData) {
        var url = 'savechart'
        if (chartData.id) {
            url = url + '/' + chartData.id.toString()
        }
        return new Promise((resolve, reject) => {
            makeApiRequest(url, { chartData: chartData }).then((id) => {
                resolve(id);
            }).catch(err => {
                console.log(err);
                reject();
            })
        })
    },

    getChartContent: function(chartId) {
        return new Promise((resolve, reject) => {
            makeApiRequest('chart', { chartId: chartId }).then((data) => {
                resolve(data);
            }).catch(err => {
                console.log(err);
                reject();
            })
        })
    },
}