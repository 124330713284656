import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Login from './components/Login/Login';
import PrivateRoute from './components/PrivateRoute';
import { TVChartContainer } from './components/TVChartContainer/index';

class App extends React.Component {
	render() {
		return (
	
			// <div className={ 'App' }>
			// 	<TVChartContainer />
			// 	<div className="fixed">
			// 		<img src={require('./assets/img/silent-logo.png')} style={{height:"20px"}}/>
			// 		<span style={{verticalAlign: "top", fontSize: "20px"}}> &nbsp; Slope Index </span>
			// 	</div> 
			// </div>
	
			<BrowserRouter basename="/trading">
				<Switch>
					<Route component={Login} path="/login" exact />
					<PrivateRoute component={TVChartContainer} path="/" exact />
				</Switch>
			</BrowserRouter>
		);
	}
}

export default App;
