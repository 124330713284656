
import { makeApiRequest } from '../Helpers/odoo-rpc.helper.js';

export default {
    onReady: (callback) => {
        makeApiRequest('config').then(data => {
            callback(data)
        }).catch(err => {
            console.log(err)
        })
    },
    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
        makeApiRequest('search_symbol', {query: userInput, exchange: exchange, symbol_type: symbolType}).then(data => {
            onResultReadyCallback(data)
        }).catch(err => {
            onResultReadyCallback([])
            console.log(err)
        })
    },
    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        makeApiRequest('symbol', { symbol: symbolName }).then(data => {
            if (data && Object.keys(data).length >= 1) {
                onSymbolResolvedCallback(data)
            } else {
                onResolveErrorCallback('Can not resolve symbol.')
            }
        }).catch(err => {
            onResolveErrorCallback(err)
        })
    },
    getBars: (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) => {
        makeApiRequest('history', { symbol: symbolInfo.ticker, resolution: resolution, from_ts: from, to_ts: to }).then(data => {
            if (data && Object.keys(data).length >= 1) {
                onHistoryCallback(data.data, data.metadata)
            } else {
                onErrorCallback('Can not resolve symbol.')
            }
        }).catch(err => {
            onErrorCallback(err)
        })
    },
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
        console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
    },
    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    },
};